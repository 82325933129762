import React, { useState } from "react";
import logo from "../Assets/logo.jpeg";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const Header = () => {
  const { t } = useTranslation();
  const [toggleIsOpen, setToggeIsOpen] = useState(false);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="cust-navbar header"
      light
      fixed="top"
    >
      <NavbarBrand className="d-block d-lg-none p-0" href="#">
        <img
          style={{}}
          src={logo}
          width="90"
          height="90"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </NavbarBrand>
      <NavbarToggler
        aria-controls="headerMenu"
        onClick={() => setToggeIsOpen(!toggleIsOpen)}
      />
      <Collapse isOpen={toggleIsOpen} navbar id="headerMenu">
        <Nav
          className="ml-auto mr-auto"
          navbar
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <NavItem>
            <NavLink href="https://ethiopiastreets.net/">{t("home")}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://ethiopiastreets.net/street-planning/">
              {t("street_planning")}
            </NavLink>
          </NavItem>
          <NavItem style={{ marginRight: 0 }}>
            <NavLink href="https://ethiopiastreets.net/streetmix-elements/">
              {t("street_elements")}
            </NavLink>
          </NavItem>
          <NavItem className="brandlogo">
            <NavLink href="/" style={{ padding: 0 }}>
              <img
                style={{}}
                src={logo}
                width="90"
                height="90"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://ethiopiastreets.net/intersections/">
              {t("intersections")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://ethiopiastreets.net/design-process/">
              {t("design_process")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://ethiopiastreets.net/street-design-library/">
              {t("street_design_library")}
            </NavLink>
          </NavItem>
          {/* <UncontrolledDropdown nav inNavbar></UncontrolledDropdown> */}
        </Nav>
      </Collapse>
      <LanguageSwitcher />
    </Navbar>
  );
};

export default Header;
