import React, { useEffect, useState } from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

const PopOver = (props) => {
  const [isShow, setIsShow] = useState(false);

  console.log("called");

  const handleChange = () => {};

  useEffect(() => {
    console.log("changed");
  }, [isShow]);

  return (
    <UncontrolledPopover
      trigger="legacy"
      placement="top"
      target={props.target}
      onChange={() => setIsShow(!isShow)}
    >
      <PopoverHeader></PopoverHeader>
      <PopoverBody>{props.body}</PopoverBody>
    </UncontrolledPopover>
  );
};

export default PopOver;
