import React, { useContext, useEffect } from "react";
import { Row, Col, Label, Input, Card } from "reactstrap";
import AppContext from "../AppContext";
import { useTranslation } from "react-i18next";

const PublicTransportCard = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const { showDispatch } = useContext(AppContext);

  const handleChange = (e) => {
    handleBRT(e.target.value);
  };

  const handleBRT = (value) => {
    console.log("value >>> ", value);
    showDispatch({ type: "SHOW-BRT", payload: value });
    if (value !== "true") {
      dispatch({ type: "BRT-STATION", payload: 0 });
      dispatch({ type: "BRTLANE-ONE-LEFT", payload: 0 });
      dispatch({ type: "BRTLANE-TWO-LEFT", payload: 0 });
      dispatch({ type: "BRTLANE-ONE-RIGHT", payload: 0 });
      dispatch({ type: "BRTLANE-TWO-RIGHT", payload: 0 });
      // dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0 });
    } else {
      dispatch({ type: "BRT-STATION", payload: 0 });
      dispatch({ type: "BRTLANE-ONE-LEFT", payload: 3.5 });
      dispatch({ type: "BRTLANE-TWO-LEFT", payload: 0 });
      dispatch({ type: "BRTLANE-ONE-RIGHT", payload: 3.5 });
      dispatch({ type: "BRTLANE-TWO-RIGHT", payload: 0 });
      dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0.5 });
      dispatch({ type: "DRIVELANE-MEDIAN-RIGHT", payload: 0.5 });
    }
  };

  useEffect(() => {
    handleBRT(true);
    // showDispatch({
    //   type: "BRT-CARD",
    //   payload: "Regular Service",
    // });
    showDispatch({
      type: "BRT-CARD",
      payload: "BRT",
    });
    hideBRTPassing();
    dispatch({ type: "HAS-BRT-SERVICE", payload: true });
    dispatch({ type: "HAS-REGULAR-SERVICE", payload: false });
    // dispatch({ type: "DRIVELANE-TYPE", payload: "sharrow" });
    dispatch({ type: "DRIVELANE-TYPE", payload: "car" });
    // if (state.data.street.driveLaneType === "car") {
    // }
    // if (state.data.street.driveLaneType === "sharrow") {
    //   dispatch({ type: "DRIVELANE-TYPE", payload: "sharrow" });
    // }
  }, []);

  const hideBRTPassing = () => {
    showDispatch({
      type: "SHOW-BRT-PASSINGLANES",
      payload: null,
    });
    dispatch({ type: "BRT-STATION", payload: 0 });
  };

  return (
    <Card
      body
      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
    >
      <div className="streetRow">
        <div className="streetTitle wrapper mb-3">
          <Label>{t("public_transport")}</Label>
        </div>
        <div className="streetcontentForm mb-4">
          <Row>
            <Col xs="12" lg="auto" className="pl-3 mb-3">
              <div class="radiobtn">
                <Input
                  onClick={(e) => {
                    handleChange(e);
                    showDispatch({
                      type: "BRT-CARD",
                      payload: "Regular Service",
                    });
                    hideBRTPassing();
                    dispatch({ type: "HAS-BRT-SERVICE", payload: false });
                    dispatch({ type: "HAS-REGULAR-SERVICE", payload: true });
                    if (state.data.street.driveLaneType === "car") {
                      dispatch({ type: "DRIVELANE-TYPE", payload: "car" });
                    }
                    if (state.data.street.driveLaneType === "sharrow") {
                      dispatch({ type: "DRIVELANE-TYPE", payload: "sharrow" });
                    }
                  }}
                  type="radio"
                  class="btn-check"
                  name="brt_card_radios"
                  id="rbBRTRegularService"
                  autocomplete="off"
                  value={null}
                />
                <Label
                  class="btn btn-outline-primary"
                  for="rbBRTRegularService"
                >
                  {t("regular_service")}
                </Label>
              </div>
            </Col>
            <Col xs="12" lg="auto" className="pl-3 mb-3">
              <div class="radiobtn">
                <Input
                  onClick={(e) => {
                    handleChange(e);
                    showDispatch({
                      type: "BRT-CARD",
                      payload: "BRT",
                    });
                    hideBRTPassing();
                    dispatch({ type: "HAS-BRT-SERVICE", payload: true });
                    dispatch({ type: "HAS-REGULAR-SERVICE", payload: false });
                    dispatch({ type: "DRIVELANE-TYPE", payload: "car" });
                  }}
                  type="radio"
                  class="btn-check"
                  name="brt_card_radios"
                  id="rbBRT"
                  autocomplete="off"
                  value={true}
                  defaultChecked={true}
                />
                <Label class="btn btn-outline-primary" for="rbBRT">
                  {t("brt")}
                </Label>
              </div>
            </Col>
            <Col xs="12" lg="auto" className="pl-3 mb-3">
              <div class="radiobtn">
                <Input
                  onClick={(e) => {
                    handleChange(e);
                    showDispatch({
                      type: "BRT-CARD",
                      payload: "No Service",
                    });
                    hideBRTPassing();
                    dispatch({ type: "HAS-BRT-SERVICE", payload: false });
                    dispatch({ type: "HAS-REGULAR-SERVICE", payload: false });
                    if (state.data.street.driveLaneType === "car") {
                      dispatch({ type: "DRIVELANE-TYPE", payload: "car" });
                    }
                    if (state.data.street.driveLaneType === "sharrow") {
                      dispatch({ type: "DRIVELANE-TYPE", payload: "sharrow" });
                    }
                  }}
                  type="radio"
                  class="btn-check"
                  name="brt_card_radios"
                  id="rbBRTNoService"
                  autocomplete="off"
                  value={null}
                />
                <Label class="btn btn-outline-primary" for="rbBRTNoService">
                  {t("no_service")}
                </Label>
              </div>
            </Col>
            <Col />
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default PublicTransportCard;

