import React, { useContext, useEffect } from "react";
import { Row, Col, Label, Input, Card } from "reactstrap";
import AppContext from "../AppContext";
import { useTranslation } from "react-i18next";

const CarriagewayCard = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const { showState } = useContext(AppContext);

  const handleTwoWayCarriageWay = () => {
    const lanes = showState.numOfLanes;
    if (lanes >= 1) {
      dispatch({
        type: "DRIVELANE-ONE-RIGHT",
        payload: 3.0,
      });
    }

    if (lanes >= 2) {
      dispatch({
        type: "DRIVELANE-MEDIAN-LEFT",
        payload: 0.5,
      });
      dispatch({
        type: "DRIVELANE-TWO-RIGHT",
        payload: 3.0,
      });
    }

    if (lanes >= 3) {
      dispatch({
        type: "DRIVELANE-THREE-RIGHT",
        payload: 3.0,
      });
    }

    if (lanes >= 4) {
      dispatch({
        type: "DRIVELANE-FOUR-RIGHT",
        payload: 3.0,
      });
    }
  }

  return (
    <Card
      body
      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
    >
      <div className="streetRow">
        <div className="streetTitle wrapper mb-3">
          <Label>{t("carriageway")}</Label>
        </div>
        <div className="streetcontentForm mb-4">
          <Row>
            <Col xs="12" lg="4" className="mb-3">
              <div class="radiobtn">
                <Input
                  type="radio"
                  id="rbCarriagewayTwoLanes"
                  name="rbCarriagewayCard"
                  defaultChecked={true}
                  onChange={(e) => {
                    if (state.data.street.segments[14].width > 0) {
                      dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0.5 });
                      dispatch({
                        type: "DRIVELANE-MEDIAN-RIGHT",
                        payload: 0.5,
                      });
                    } else {
                      dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0 });
                      dispatch({ type: "DRIVELANE-MEDIAN-RIGHT", payload: 0 });
                    }
                    dispatch({ type: "CARRIAGE-WAY-TYPE", payload: "TwoWay" });

                    handleTwoWayCarriageWay()
                  }}
                />
                <Label for="rbCarriagewayTwoLanes">{t("two-way")}</Label>
              </div>
            </Col>
            <Col xs="12" lg="4" hidden={state.data.street.showOneWayCarriageway} className="mb-3">
              <div class="radiobtn">
                <Input
                  type="radio"
                  id="rbCarriagewayOneLane"
                  name="rbCarriagewayCard"
                  onChange={(e) => {
                    if (state.data.street.segments[14].width > 0) {
                      dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0.5 });
                      dispatch({
                        type: "DRIVELANE-MEDIAN-RIGHT",
                        payload: 0.5,
                      });
                    } else {
                      dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0 });
                      dispatch({ type: "DRIVELANE-MEDIAN-RIGHT", payload: 0 });
                    }
                    dispatch({ type: "CARRIAGE-WAY-TYPE", payload: "OneWay" });
                    dispatch({
                      type: "DRIVELANE-ONE-RIGHT",
                      payload: 0,
                    });
                    dispatch({
                      type: "DRIVELANE-TWO-RIGHT",
                      payload: 0,
                    });
                    dispatch({
                      type: "DRIVELANE-THREE-RIGHT",
                      payload: 0,
                    });
                    dispatch({
                      type: "DRIVELANE-FOUR-RIGHT",
                      payload: 0,
                    });
                  }}
                />
                <Label for="rbCarriagewayOneLane">One-way</Label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default CarriagewayCard;
