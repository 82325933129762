import React, { useState, useReducer, useRef } from "react";
// import {ContextDevTool} from 'react-context-devtool';
import banner from "./Assets/made_with_str_mix.png";

import "./App.css";
import { Form, Container, Button } from "reactstrap";
import RowCard from "./components/RowCard";
import StreetTopologyCard from "./components/StreetTopologyCard";
import WalkingCard from "./components/WalkingCard";
import FoothpathLeftCard from "./components/FoothpathLeftCard";
import FoothpathRightCard from "./components/FoothpathRightCard";
import CyclingCard from "./components/CyclingCard";
import PublicTransportCard from "./components/PublicTransportCard";
import BrtCard from "./components/BrtCard";
import BrtPassingLanesCard from "./components/BrtPassingLanesCard";
import CarriagewayCard from "./components/CarriagewayCard";
import CarriagewayLaneCard from "./components/CarriagewayLaneCard";
import ServiceLaneCard from "./components/ServiceLaneCard";
import ParkingCard from "./components/ParkingCard";
import ParkingCardRight from "./components/ParkingCardRight";
import Header from "./components/Header";
import Footer from "./components/Footer";

import axios from "axios";

import { AppContextProvider } from "./AppContext";
// import swal from "sweetalert";

import { useTranslation } from "react-i18next";

const domain = `https://stmix.ethiopiastreets.net`;

// TODO
// Make segment ids random guids
const initialState = {
  name: null,
  show: true,
  data: {
    street: {
      schemaVersion: 25,
      showAnalytics: true,
      width: 50,
      units: 2,
      location: null,
      userUpdated: false,
      environment: "day",
      leftBuildingHeight: 4,
      rightBuildingHeight: 3,
      leftBuildingVariant: "narrow",
      rightBuildingVariant: "wide",
      streetTopology: "MixedUse",
      carriageWayType: "TwoWay",
      driveLaneType: "car",
      driveLaneVariantLeft: "inbound",
      driveLaneVariantRight: "outbound",
      tempParkingValueLeft: 0,
      tempParkingValueRight: 0,
      showOneWayCarriageway: true,
      showCycleTrackBothSides: true,
      hasCyclesInCarriageway: false,
      hasCyclesOneWay: false,
      hasBRTService: true,
      hasRegularService: false,
      numberOfLanes: 0,
      streetType: "Boulevard",
      segments: [
        {
          id: "K-ZH7BTyqS3bOitO4xqxM",
          type: "sidewalk",
          variantString: "dense",
          width: 0,
        },
        {
          id: "QyUmG3zUBgXJBFWFKDNr4",
          type: "sidewalk-tree",
          variantString: "big",
          width: 0,
        },
        {
          id: "3KLsf2HwEjKF18nL-BHZi",
          type: "bike-lane",
          variantString: "inbound|green|sidewalk",
          width: 0,
        },
        // {
        //   id: "i4A6fvfasAffsyNeApIxx",
        //   type: "bike-lane",
        //   variantString: "inbound|green|sidewalk",
        //   width: 0,
        // },
        {
          id: "y5AZ-dSyQbEMW9TlXi1W_",
          type: "sidewalk-bike-rack",
          variantString: "right|sidewalk-parallel",
          width: 0,
        },
        {
          id: "a4b_i-DSnUIwOUhkBAm2B",
          type: "bikeshare",
          variantString: "right|sidewalk",
          width: 0,
        },
        {
          id: "rHFgWEHvCHa8lUfLw3t1_",
          type: "divider",
          variantString: "median",
          width: 0,
        },
        {
          id: "irtvQglKckuio4gf36Y3b",
          type: "parking-lane",
          variantString: "inbound|left",
          width: 0,
        },
        // {
        //   id: "2yYdMwth-Ds4w6YMoTng6",
        //   type: "turn-lane",
        //   variantString: "inbound|left-straight",
        //   width: 0,
        // },
        {
          id: "2yYdMwth-Ds4w6YMoTng6",
          type: "drive-lane",
          variantString: "inbound|car",
          width: 0,
        },
        {
          id: "rHFgWEHvCHa8lUfLw3t6_",
          type: "divider",
          variantString: "median",
          width: 0,
        },
        {
          id: "1MwyTD7dBCY8_bZ-6mGq1",
          type: "drive-lane",
          variantString: "inbound|car",
          width: 0,
        },
        {
          id: "XBZNjqLUx3Jd42XL39QSv",
          type: "drive-lane",
          variantString: "inbound|car",
          width: 0,
        },
        {
          id: "1MwyTD7dBCY8_bZ-6mGq3",
          type: "drive-lane",
          variantString: "inbound|car",
          width: 0,
        },
        {
          id: "XBZNjqLUx3Jd44XL39QSv",
          type: "drive-lane",
          variantString: "inbound|car",
          width: 0,
        },
        {
          id: "rHFgWEHvCHa8lUfLw3t2_",
          type: "divider",
          variantString: "median",
          width: 0,
        },
        {
          id: "5ac756e0-dcr45-11eb-929d-217d31d73d00",
          type: "brt-lane",
          variantString: "inbound|regular",
          width: 0,
        },
        {
          id: "5ac756e0-dcr45-11eb-929d-217d31d73d11",
          type: "brt-lane",
          variantString: "inbound|regular",
          width: 0,
        },
        {
          id: "5ac756e0-dca5-11eb-929d-217d31d73d00",
          type: "brt-station",
          variantString: "center",
          width: 0,
        },
        {
          id: "5ac756e0-dcr45-11eb-929d-217d31d73d22",
          type: "brt-lane",
          variantString: "outbound|regular",
          width: 0,
        },
        {
          id: "5ac756e0-dcr45-11eb-929d-217d31d73d33",
          type: "brt-lane",
          variantString: "outbound|regular",
          width: 0,
        },
        {
          id: "rHFgWEHvCHa8lUfLw3t3_",
          type: "divider",
          variantString: "median",
          width: 0,
        },
        {
          id: "QaA1dG-nvd-onpkoHvTRk",
          type: "drive-lane",
          variantString: "outbound|car",
          width: 0,
        },
        {
          id: "A2GaEzMDg78IhmF6STzvX",
          type: "drive-lane",
          variantString: "outbound|car",
          width: 0,
        },
        {
          id: "QaA3dG-nvd-onpkoHvTRk",
          type: "drive-lane",
          variantString: "outbound|car",
          width: 0,
        },
        {
          id: "A4GaEzMDg78IhmF6STzvX",
          type: "drive-lane",
          variantString: "outbound|car",
          width: 0,
        },
        // {
        //   id: "zl3l73XIY3-qEHLOHpUww",
        //   type: "turn-lane",
        //   variantString: "outbound|right-straight",
        //   width: 0,
        // },
        {
          id: "rHFgWEHvCHa8lUfLw3t5_",
          type: "divider",
          variantString: "median",
          width: 0,
        },
        {
          id: "zl3l73XIY3-qEHLOHpUww",
          type: "drive-lane",
          variantString: "outbound|car",
          width: 0,
        },
        {
          id: "Xv49uCu6pTXOHvzO_oU4C",
          type: "parking-lane",
          variantString: "outbound|right",
          width: 0,
        },
        {
          id: "rHFgWEHvCHa8lUfLw3t4_",
          type: "divider",
          variantString: "median",
          width: 0,
        },
        {
          id: "a4b_i-DSnUIwOUhkBAm4B",
          type: "bikeshare",
          variantString: "left|sidewalk",
          width: 0,
        },
        {
          id: "y5AZ-dSyQbEMW9TlXi3W_",
          type: "sidewalk-bike-rack",
          variantString: "left|sidewalk-parallel",
          width: 0,
        },
        // {
        //   id: "ZkD-W7UprX2lsPRbch6gU",
        //   type: "bike-lane",
        //   variantString: "outbound|green|sidewalk",
        //   width: 0,
        // },
        {
          id: "ZkD-W7UprX2lsPRbch6gU",
          type: "bike-lane",
          variantString: "outbound|green|sidewalk",
          width: 0,
        },
        {
          id: "OAOckvoIoIHMWUUB8qANt",
          type: "sidewalk-tree",
          variantString: "big",
          width: 0,
        },
        {
          id: "ltlZ-7hUTG34rjAe4LhUE",
          type: "sidewalk",
          variantString: "dense",
          width: 0,
        },
      ],
      editCount: 0,
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ROW-WIDTH": {
      const newState = Object.create(state);
      newState.data.street.width = action.payload;
      return newState;
    }

    case "STREET-TYPE": {
      const newState = Object.create(state);
      newState.data.street.streetType = action.payload;
      return newState;
    }

    case "STREET-TOPOLOGY": {
      const newState = Object.create(state);
      newState.data.street.streetTopology = action.payload;
      return newState;
    }

    case "CARRIAGE-WAY-TYPE": {
      const newState = Object.create(state);

      newState.data.street.carriageWayType = action.payload;
      // if (newState.data.street.carriageWayType === "TwoWay") {
      //   newState.data.street.driveLaneVariantRight = "outbound";
      // } else {
      //   newState.data.street.driveLaneVariantRight = "inbound";
      // }

      if (newState.data.street.carriageWayType === "OneWay") {
        newState.data.street.driveLaneVariantLeft = "outbound";
        newState.data.street.driveLaneVariantRight = "outbound";
      } else {
        newState.data.street.driveLaneVariantLeft = "inbound";
        newState.data.street.driveLaneVariantRight = "outbound";
      }

      newState.data.street.segments[9].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[10].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[11].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[12].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[20].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[21].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[22].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[23].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[26].variantString =
        newState.data.street.driveLaneVariantRight + "|right-straight";

      // change the no of lines accordingly
      if (
        newState.data.street.numberOfLanes > 0 &&
        action.payload !== "TwoWay"
      ) {
        newState.data.street.segments[12].width = 3.25;
      } else if (
        newState.data.street.numberOfLanes > 0 &&
        action.payload === "TwoWay"
      ) {
        newState.data.street.segments[12].width = 3.0;
      }

      return newState;
    }

    case "SIDEWALK-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[0].width = action.payload;
      return newState;
    }

    case "SIDEWALK-TREE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[1].width = action.payload;
      return newState;
    }

    case "BIKELANE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[2].width = action.payload;

      return newState;
    }

    case "BIKERACK-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[3].width = action.payload;
      return newState;
    }

    case "BIKESHARE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[4].width = action.payload;
      return newState;
    }

    case "BIKELANE-MEDIAN-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[5].width = action.payload;
      return newState;
    }

    case "PARKING-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[6].width = action.payload;
      return newState;
    }

    case "SERVICELANE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[7].width = action.payload;
      return newState;
    }

    case "SERVICELANE-MEDIAN-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[8].width = action.payload;
      return newState;
    }

    case "DRIVELANE-ONE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[9].width = action.payload;
      return newState;
    }

    case "DRIVELANE-TWO-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[10].width = action.payload;
      return newState;
    }

    case "DRIVELANE-THREE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[11].width = action.payload;
      return newState;
    }

    case "DRIVELANE-FOUR-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[12].width = action.payload;
      return newState;
    }

    case "DRIVELANE-MEDIAN-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[13].width = action.payload;
      return newState;
    }

    case "BRTLANE-ONE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[14].width = action.payload;
      return newState;
    }

    case "BRTLANE-TWO-LEFT": {
      const newState = Object.create(state);
      newState.data.street.segments[15].width = action.payload;
      return newState;
    }

    case "BRT-STATION": {
      const newState = Object.create(state);
      newState.data.street.segments[16].width = action.payload;
      return newState;
    }

    case "BRTLANE-ONE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[17].width = action.payload;
      return newState;
    }

    case "BRTLANE-TWO-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[18].width = action.payload;
      return newState;
    }

    case "DRIVELANE-MEDIAN-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[19].width = action.payload;
      return newState;
    }

    case "DRIVELANE-ONE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[20].width = action.payload;
      return newState;
    }

    case "DRIVELANE-TWO-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[21].width = action.payload;
      return newState;
    }

    case "DRIVELANE-THREE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[22].width = action.payload;
      return newState;
    }

    case "DRIVELANE-FOUR-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[23].width = action.payload;
      return newState;
    }

    case "SERVICELANE-MEDIAN-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[24].width = action.payload;
      return newState;
    }

    case "SERVICELANE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[25].width = action.payload;
      return newState;
    }

    case "PARKING-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[26].width = action.payload;
      return newState;
    }

    case "BIKELANE-MEDIAN-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[27].width = action.payload;
      return newState;
    }

    case "BIKESHARE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[28].width = action.payload;
      return newState;
    }

    case "BIKERACK-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[29].width = action.payload;
      return newState;
    }

    case "BIKELANE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[30].width = action.payload;
      if (newState.data.street.hasCyclesOneWay) {
        newState.data.street.segments[30].variantString =
          "twoway-right|green|road";
      } else {
        newState.data.street.segments[30].variantString =
          "inbound|green|sidewalk";
      }
      if (
        !newState.data.street.hasCyclesOneWay &&
        !newState.data.street.hasCyclesInCarriageway
      ) {
        newState.data.street.segments[30].variantString =
          "outbound|green|sidewalk";
      }
      return newState;
    }

    case "SIDEWALK-TREE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[31].width = action.payload;
      return newState;
    }

    case "SIDEWALK-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.segments[32].width = action.payload;
      return newState;
    }

    case "DRIVELANE-TYPE": {
      const newState = Object.create(state);

      newState.data.street.driveLaneType = action.payload;

      newState.data.street.segments[9].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[10].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[11].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[12].variantString =
        newState.data.street.driveLaneVariantLeft +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[20].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[21].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[22].variantString =
        newState.data.street.driveLaneVariantRight +
        "|" +
        newState.data.street.driveLaneType;
      newState.data.street.segments[23].variantString =
        newState.data.street.driveLaneVariantRight + "|right-straight";

      let LaneLeftSegment =
        newState.data.street.numberOfLanes > 1
          ? 12 - newState.data.street.numberOfLanes + 1
          : 12;
      let LaneRightSegment =
        newState.data.street.numberOfLanes > 1
          ? 20 + newState.data.street.numberOfLanes - 1
          : 20;

      if (!newState.data.street.hasBRTService) {
        newState.data.street.segments[13].width = 0;
      }

      if (newState.data.street.hasCyclesInCarriageway) {
        newState.data.street.segments[LaneLeftSegment].variantString =
          newState.data.street.driveLaneVariantLeft + "|car-with-bike";
        newState.data.street.segments[LaneRightSegment].variantString =
          newState.data.street.driveLaneVariantRight + "|car-with-bike";
        newState.data.street.segments[5].variantString = "median";
        newState.data.street.segments[27].variantString = "median";
      } else {
        newState.data.street.segments[5].variantString = "flowers";
        newState.data.street.segments[27].variantString = "flowers";
      }

      if (newState.data.street.hasRegularService) {
        newState.data.street.segments[LaneLeftSegment].variantString =
          newState.data.street.driveLaneVariantLeft + "|car-with-bus";
        newState.data.street.segments[LaneRightSegment].variantString =
          newState.data.street.driveLaneVariantRight + "|car-with-bus";

        newState.data.street.segments[13].width = 0;
      }

      if (
        newState.data.street.hasRegularService &&
        newState.data.street.hasCyclesInCarriageway
      ) {
        newState.data.street.segments[LaneRightSegment].variantString =
          newState.data.street.driveLaneVariantRight + "|car-with-bike";
      }

      // if (action.payload === "sharrow") {
      //   newState.data.street.segments[8].width = 0;
      //   newState.data.street.segments[9].width = 0;
      //   newState.data.street.segments[10].width = 3.6;
      //   newState.data.street.segments[11].width = 3.6;
      //   newState.data.street.segments[19].width = 3.6;
      //   newState.data.street.segments[20].width = 3.6;
      //   newState.data.street.segments[21].width = 0;
      //   newState.data.street.segments[22].width = 0;
      // }

      // if (action.payload === "car") {
      //   newState.data.street.segments[8].width = 0;
      //   newState.data.street.segments[9].width = 0;
      //   newState.data.street.segments[10].width = 3.0;
      //   newState.data.street.segments[11].width = 3.0;
      //   newState.data.street.segments[19].width = 3.0;
      //   newState.data.street.segments[20].width = 3.0;
      //   newState.data.street.segments[21].width = 0;
      //   newState.data.street.segments[22].width = 0;
      // }

      return newState;
    }

    case "TEMP-PARKING-VALUE-LEFT": {
      const newState = Object.create(state);
      newState.data.street.tempParkingValueLeft = action.payload;
      return newState;
    }

    case "TEMP-PARKING-VALUE-RIGHT": {
      const newState = Object.create(state);
      newState.data.street.tempParkingValueRight = action.payload;
      return newState;
    }

    case "SHOW-ONE-WAY-CARRIAGEWAY": {
      const newState = Object.create(state);
      newState.data.street.showOneWayCarriageway = action.payload;
      return newState;
    }

    case "SHOW-CYCLETRACK-BOTH-SIDES": {
      const newState = Object.create(state);
      newState.data.street.showCycleTrackBothSides = action.payload;
      return newState;
    }

    case "HAS-BRT-SERVICE": {
      const newState = Object.create(state);
      newState.data.street.hasBRTService = action.payload;

      return newState;
    }

    case "HAS-REGULAR-SERVICE": {
      const newState = Object.create(state);
      newState.data.street.hasRegularService = action.payload;

      return newState;
    }

    case "HAS-CYCLE-CARRIAGEWAY": {
      const newState = Object.create(state);
      newState.data.street.hasCyclesInCarriageway = action.payload;

      return newState;
    }
    case "HAS-CYCLE-ONEWAY": {
      const newState = Object.create(state);
      newState.data.street.hasCyclesOneWay = action.payload;

      return newState;
    }

    case "NO-OF-LANES": {
      const newState = Object.create(state);
      newState.data.street.numberOfLanes = action.payload;
      if (
        action.payload > 0 &&
        newState.data.street.carriageWayType !== "TwoWay"
      ) {
        newState.data.street.segments[12].width = 3.25;
      }
      return newState;
    }

    default:
      return state;
  }
};

const showReducer = (state, action) => {
  switch (action.type) {
    case "SHOW-FOOTPATH-RIGHT":
      return { ...state, showRightPath: action.payload };

    case "SHOW-FOOTPATH-LEFT":
      return { ...state, showLeftPath: action.payload };

    case "SHOW-CYCLING":
      return { ...state, showCycling: action.payload };

    case "SHOW-PUBLIC-TRANSPORT":
      return { ...state, showPublicTransport: action.payload };

    case "SHOW-BRT":
      return { ...state, showBRT: action.payload };

    case "SHOW-BRT-PASSINGLANES":
      return { ...state, showPassingLanes: action.payload };

    case "SHOW-CARRIAGEWAY":
      return { ...state, showCarriageway: action.payload };

    case "SHOW-CARRIAGEWAY-LANES":
      return { ...state, showCarriagewayLanes: action.payload };

    case "CARRIAGEWAY-LANES":
      return { ...state, numOfLanes: action.payload };

    case "SHOW-SERVICE-LANES":
      return { ...state, showServiceLane: action.payload };

    case "SHOW-PARKING":
      return { ...state, showParking: action.payload };

    case "CYCLING-CARD":
      return { ...state, selectedItemCyclingCard: action.payload };

    case "BRT-CARD":
      return { ...state, selectedItemBRT: action.payload };

    default:
      return state;
  }
};

const initialShowState = {
  showRightPath: true,
  showLeftPath: true,
  showCycling: true,
  showPublicTransport: true,
  showBRT: true,
  showPassingLanes: true,
  showServiceLane: true,
  showCarriageway: true,
  showCarriagewayLanes: true,
  numOfLanes: 0,
  showParking: true,
  selectedItemCyclingCard: "C. track: both sides",
  selectedItemBRT: "Regular Service",
};

const App = () => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [vmessage, setVmessage] = useState("");
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showState, showDispatch] = useReducer(showReducer, initialShowState);

  const TO_FEET = 3.333333333333333333333333;

  let totalWidth = 0;
  let temp_segments = state.data.street.segments.filter((seg) => seg.width > 0);
  // console.log("temp_segments >> ", initialState);
  temp_segments.forEach((item, index) => {
    // console.log(item);
    totalWidth += Number(item.width);
  });

  // console.log("total_width >>>>>>>>> ", totalWidth);

  const handleSubmit = (event) => {
    event.preventDefault();

    let temp_segments = state.data.street.segments.filter(
      (seg) => seg.width > 0
    );

    let segments = temp_segments.map((seg) => ({
      ...seg,
      width: seg.width * TO_FEET,
    }));
    const street_mix_data = {
      name: null,
      show: true,
      data: {
        street: {
          schemaVersion: 25,
          showAnalytics: true,
          width: 30,
          units: 2,
          location: null,
          userUpdated: false,
          environment: "day",
          leftBuildingHeight: 4,
          rightBuildingHeight: 3,
          leftBuildingVariant: "narrow",
          rightBuildingVariant: "wide",
          segments: [],
          editCount: 0,
        },
      },
    };

    street_mix_data.data.street.width = state.data.street.width;

    let total_width = 0;
    temp_segments.forEach((item, index) => {
      // console.log(item);
      total_width += Number(item.width);
    });

    // console.log(
    // `Total components: ${total_width} Street width: ${street_mix_data.data.street.width}`
    // );

    console.log(street_mix_data);

    if (Number(total_width) > Number(street_mix_data.data.street.width)) {
      setVmessage(
        `The total width of all components is '${total_width.toFixed(
          1
        )} m' which is greater than the street width set at '${
          street_mix_data.data.street.width
        } m'. Please increase the street width or reduce the number of components.'`
      );
      // swal({
      //   title: "Increase Street Width",
      //   text: `The total width of all components is '${total_width.toFixed(
      //     1
      //   )} m' which is greater than the street width set at '${
      //     street_mix_data.data.street.width
      //   } m'. Please increase the street width or reduce the number of components.'`,
      //   icon: "warning",
      //   button: "Ok",
      // });
      // setLoading(false);
      scrollToTop();
      return;
    } else {
      setVmessage("");
    }
    for (let index = 0; index < segments.length - 1; index++) {
      if (
        segments[index].type === "divider" &&
        segments[index + 1].type === "divider" &&
        segments[index].width === segments[index + 1].width
      ) {
        segments[index + 1].width = parseFloat(segments[index + 1].width) * 2;
        segments.splice(index, 1);
        break;
      }
    }
    street_mix_data.data.street.segments = segments;
    street_mix_data.data.street.width = state.data.street.width * TO_FEET;
    setLoading(true);
    axios
      .post(domain + `/api/v1/streets`, street_mix_data)
      .then((res) => {
        window.location.href = domain + `/-/${res.data.namespacedId}?lang=${i18n.language}`;
        // console.log(res);
        console.log(res.data);
        // setLoading(false);
        setErrorText("");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setErrorText("Some error occurred");
      });
  };

  const scrollRef = useRef();
  const scrollToTop = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header />
      <img src={banner} alt="banner" className="banner-style"></img>
      <Container fluid className="main-form">
        <Form onSubmit={handleSubmit} className="crossSection">
          <h4>{t("create_cross_section")}</h4>
          <AppContextProvider
            value={{ showState, showDispatch, state, dispatch }}
          >
            {/*Add image here in css*/}
            {/* <ContextDevTool context={AppContext} id="uniqContextId" displayName="App Context" /> */}
            <span ref={scrollRef} />
            <RowCard vmessage={vmessage} totalWidth={totalWidth} />
            {/* <p /> */}
            <StreetTopologyCard />
            {/* <p /> */}
            <WalkingCard />
            {/* <p /> */}
            {showState.showLeftPath && <FoothpathLeftCard />}
            {/* <p /> */}
            {showState.showRightPath && <FoothpathRightCard />}
            {/* <p /> */}
            {showState.showCycling && (
              <CyclingCard rowWidth={state.data.street.width} />
            )}
            {console.log("state.data >> ", state.data.street)}
            {/* <p /> */}
            {showState.showPublicTransport && <PublicTransportCard />}
            {/* <p /> */}
            {showState.showBRT && <BrtCard />}
            {/* <p /> */}
            {showState.showPassingLanes && <BrtPassingLanesCard />}
            {/* <p /> */}
            {showState.showCarriageway && <CarriagewayCard />}
            {/* <p /> */}
            {showState.showCarriagewayLanes && <CarriagewayLaneCard />}
            {/* <p /> */}
            {showState.showServiceLane && <ServiceLaneCard />}
            {/* <p /> */}
            {showState.showParking && <ParkingCard />}
            {showState.showParking && <ParkingCardRight />}
            {/* <p /> */}
            <div style={{ textAlign: "center" }}>
              <div className="card card-body border-0 crossbtnRow">
                <Button
                  className="crossSection-btn"
                  type="submit"
                  style={{}}
                  disabled={loading}
                >
                  {loading ? (
                    <span>{t("processing")}...</span>
                  ) : (
                    <span className="d-flex justify-center">
                      {t("goto_cross_section")}
                    </span>
                  )}
                </Button>
              </div>
            </div>
            {errorText ? (
              <div style={{ color: "red", padding: "15px" }}>{errorText}</div>
            ) : (
              ""
            )}
          </AppContextProvider>
        </Form>
      </Container>
      <Footer />
    </div>
  );
};

export default App;

