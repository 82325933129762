import React, { useContext, useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import swal from "sweetalert";
import {
  Row,
  Col,
  Label,
  Input,
  Card,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import AppContext from "../AppContext";
import { errorMsgCard } from "./Utils";
import { hideToolTip } from "./Utils";
import { useTranslation } from "react-i18next";

const WalkingCard = () => {
  const { t } = useTranslation();
  const { showDispatch } = useContext(AppContext);
  const { state, dispatch } = useContext(AppContext);
  const [shared, setShared] = useState(false);
  const [footpathOneSide, setFootpathOneSide] = useState(false);

  const footpathBothSide = () => {
    setShared(false);
    setFootpathOneSide(false);
    showDispatch({ type: "SHOW-FOOTPATH-LEFT", payload: true });
    showDispatch({
      type: "SHOW-FOOTPATH-RIGHT",
      payload: true,
    });
    dispatch({ type: "SIDEWALK-LEFT", payload: 2 });
    dispatch({ type: "SIDEWALK-TREE-LEFT", payload: 1 });
    dispatch({ type: "SIDEWALK-RIGHT", payload: 2 });
    dispatch({ type: "SIDEWALK-TREE-RIGHT", payload: 1 });

    showDispatch({ type: "SHOW-CYCLING", payload: true });

    showDispatch({
      type: "SHOW-PUBLIC-TRANSPORT",
      payload: true,
    });

    showDispatch({ type: "SHOW-BRT", payload: null });
    showDispatch({
      type: "SHOW-BRT-PASSINGLANES",
      payload: null,
    });

    showDispatch({ type: "SHOW-CARRIAGEWAY", payload: true });
    showDispatch({
      type: "SHOW-CARRIAGEWAY-LANES",
      payload: true,
    });
    dispatch({ type: "DRIVELANE-TYPE", payload: "car" });

    showDispatch({ type: "SHOW-SERVICE-LANES", payload: true });

    showDispatch({ type: "SHOW-PARKING", payload: true });

    dispatch({
      type: "DRIVELANE-ONE-LEFT",
      payload: 0,
    });
  };

  useEffect(() => {
    footpathBothSide();
  }, []);

  return (
    <Card
      body
      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
    >
      <div className="streetRow">
        <div className="streetTitle wrapper mb-3">
          <Label>{t("walking")}</Label>
        </div>
        <div className="streetcontentForm mb-4">
          <Row class="d-flex">
            <Col xs="12" lg="auto" className="pl-3 mb-3">
              <div id="divSharedSpaceWalking" className="radiobtn">
                <Input
                  onClick={() => {
                    hideToolTip("#rbFootpathSharedSpace");
                    setShared(true);
                    setFootpathOneSide(false);
                    showDispatch({ type: "SHOW-FOOTPATH-LEFT", payload: null });
                    showDispatch({
                      type: "SHOW-FOOTPATH-RIGHT",
                      payload: null,
                    });
                    dispatch({ type: "SIDEWALK-LEFT", payload: 0 });
                    dispatch({ type: "SIDEWALK-TREE-LEFT", payload: 0 });
                    dispatch({ type: "SIDEWALK-RIGHT", payload: 0 });
                    dispatch({ type: "SIDEWALK-TREE-RIGHT", payload: 0 });

                    showDispatch({ type: "SHOW-CYCLING", payload: null });
                    dispatch({ type: "BIKELANE-LEFT", payload: 0 });
                    dispatch({ type: "BIKELANE-RIGHT", payload: 0 });
                    dispatch({ type: "BIKELANE-MEDIAN-LEFT", payload: 0 });
                    dispatch({ type: "BIKELANE-MEDIAN-RIGHT", payload: 0 });

                    showDispatch({
                      type: "SHOW-PUBLIC-TRANSPORT",
                      payload: null,
                    });

                    showDispatch({ type: "SHOW-BRT", payload: null });
                    showDispatch({
                      type: "SHOW-BRT-PASSINGLANES",
                      payload: null,
                    });
                    dispatch({ type: "BRT-STATION", payload: 0 });
                    dispatch({ type: "BRTLANE-ONE-LEFT", payload: 0 });
                    dispatch({ type: "BRTLANE-TWO-LEFT", payload: 0 });
                    dispatch({ type: "BRTLANE-ONE-RIGHT", payload: 0 });
                    dispatch({ type: "BRTLANE-TWO-RIGHT", payload: 0 });

                    showDispatch({ type: "SHOW-CARRIAGEWAY", payload: null });
                    showDispatch({
                      type: "SHOW-CARRIAGEWAY-LANES",
                      payload: null,
                    });
                    dispatch({
                      type: "DRIVELANE-TYPE",
                      payload: "pedestrian",
                    });
                    dispatch({
                      type: "DRIVELANE-ONE-LEFT",
                      payload: state.data.street.width,
                    });
                    dispatch({ type: "DRIVELANE-TWO-LEFT", payload: 0 });
                    dispatch({ type: "DRIVELANE-THREE-LEFT", payload: 0 });
                    dispatch({ type: "DRIVELANE-FOUR-LEFT", payload: 0 });

                    dispatch({ type: "DRIVELANE-ONE-RIGHT", payload: 0 });
                    dispatch({ type: "DRIVELANE-TWO-RIGHT", payload: 0 });
                    dispatch({ type: "DRIVELANE-THREE-RIGHT", payload: 0 });
                    dispatch({ type: "DRIVELANE-FOUR-RIGHT", payload: 0 });

                    dispatch({ type: "DRIVELANE-MEDIAN-LEFT", payload: 0 });
                    dispatch({ type: "DRIVELANE-MEDIAN-RIGHT", payload: 0 });

                    showDispatch({ type: "SHOW-SERVICE-LANES", payload: null });
                    dispatch({ type: "SERVICELANE-LEFT", payload: 0 });
                    dispatch({ type: "SERVICELANE-RIGHT", payload: 0 });
                    
                    dispatch({ type: "SERVICELANE-MEDIAN-LEFT", payload: 0 });
                    dispatch({ type: "SERVICELANE-MEDIAN-RIGHT", payload: 0 });

                    showDispatch({ type: "SHOW-PARKING", payload: null });
                    dispatch({ type: "PARKING-LEFT", payload: 0 });
                    dispatch({ type: "PARKING-RIGHT", payload: 0 });

                    if (state.data.street.width > 20) {
                      // swal({
                      //   text: "Shared space is not recommended for wide streets",
                      //   icon: "warning",
                      //   button: "Ok",
                      // });
                      //alert("Shared space is not recommended for wide streets");
                    }
                  }}
                  type="radio"
                  class="btn-check"
                  name="walking_card_radios"
                  id="rbFootpathSharedSpace"
                  autocomplete="off"
                />
                <Label
                  class="btn btn-outline-primary"
                  for="rbFootpathSharedSpace"
                >
                  {t("shared_space")}
                </Label>
              </div>
              <UncontrolledPopover
                trigger="legacy"
                placement="top"
                target="divSharedSpaceWalking"
              >
                <PopoverHeader></PopoverHeader>
                <PopoverBody>
                {t("shared_space_desc")}
                  <FaInfoCircle className="text-info" />
                </PopoverBody>
              </UncontrolledPopover>
            </Col>
            <Col xs="12" lg="auto" className="pl-3 mb-3">
              <div class="radiobtn" id="divFootpathBothSide">
                <Input
                  onClick={() => {
                    footpathBothSide();
                    hideToolTip("#rbFootpathBothSides");
                  }}
                  type="radio"
                  class="btn-check"
                  name="walking_card_radios"
                  id="rbFootpathBothSides"
                  autocomplete="off"
                  defaultChecked={true}
                />
                <Label
                  class="btn btn-outline-primary"
                  for="rbFootpathBothSides"
                >
                  {t("footpath_both_sides")}
                </Label>
              </div>

              <UncontrolledPopover
                trigger="legacy"
                placement="top"
                target="divFootpathBothSide"
              >
                <PopoverHeader></PopoverHeader>
                <PopoverBody>
                {t("footpath_both_sides_desc")}
                  <FaInfoCircle className="text-info" />
                </PopoverBody>
              </UncontrolledPopover>
            </Col>
            <Col xs="12" lg="auto" className="pl-3 mb-3">
              <div class="radiobtn" id="divFootpathOneSide">
                <Input
                  onClick={() => {
                    hideToolTip("#rbFootpathOneSide");
                    setShared(false);
                    setFootpathOneSide(true);
                    showDispatch({ type: "SHOW-FOOTPATH-LEFT", payload: true });
                    showDispatch({
                      type: "SHOW-FOOTPATH-RIGHT",
                      payload: null,
                    });
                    dispatch({ type: "SIDEWALK-LEFT", payload: 2 });
                    dispatch({ type: "SIDEWALK-TREE-LEFT", payload: 1 });
                    dispatch({ type: "SIDEWALK-RIGHT", payload: 0 });
                    dispatch({ type: "SIDEWALK-TREE-RIGHT", payload: 0 });

                    showDispatch({ type: "SHOW-CYCLING", payload: true });

                    showDispatch({
                      type: "SHOW-PUBLIC-TRANSPORT",
                      payload: true,
                    });

                    showDispatch({ type: "SHOW-BRT", payload: null });
                    showDispatch({
                      type: "SHOW-BRT-PASSINGLANES",
                      payload: null,
                    });

                    showDispatch({ type: "SHOW-CARRIAGEWAY", payload: true });
                    showDispatch({
                      type: "SHOW-CARRIAGEWAY-LANES",
                      payload: true,
                    });
                    dispatch({ type: "DRIVELANE-TYPE", payload: "car" });
                    showDispatch({ type: "SHOW-SERVICE-LANES", payload: true });

                    showDispatch({ type: "SHOW-PARKING", payload: true });

                    if (
                      state.data.street.streetType === "Avenue" ||
                      state.data.street.streetType === "Boulevard"
                    ) {
                      // swal({
                      //   text: "Consider adding footpaths on both sides to improve safety",
                      //   icon: "warning",
                      //   button: "Ok",
                      // });
                    }
                  }}
                  type="radio"
                  class="btn-check"
                  name="walking_card_radios"
                  id="rbFootpathOneSide"
                  autocomplete="off"
                />
                <Label class="btn btn-outline-primary" for="rbFootpathOneSide">
                {t("footpath_one_side")}
                </Label>
              </div>

              <UncontrolledPopover
                trigger="legacy"
                placement="top"
                target="divFootpathOneSide"
              >
                <PopoverHeader></PopoverHeader>
                <PopoverBody>
                {t("footpath_one_side_desc")}
                  <FaInfoCircle className="text-info" />
                </PopoverBody>
              </UncontrolledPopover>
            </Col>
            <Col />
            <Col xs="12" lg="auto" className="mb-3 icon-small">
              {shared &&
                state.data.street.width > 12 &&
                errorMsgCard(
                  t("shared_space_not_recommended")
                )}
              {footpathOneSide &&
                (state.data.street.streetType === "Avenue" ||
                  state.data.street.streetType === "Boulevard") &&
                errorMsgCard(
                  t("footpath_one_side_desc")
                )}
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default WalkingCard;
