import React, { useState, useContext, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import {
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  Card,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  InputGroupText,
} from "reactstrap";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { handleNumber, hideToolTip } from "./Utils";

import AppContext from "../AppContext";
import PopOver from "./PopOver";
// import convertLayerAtRulesToControlComments from "tailwindcss/lib/lib/convertLayerAtRulesToControlComments";
import { useTranslation } from "react-i18next";

const RowCard = (props) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const { showState } = useContext(AppContext);
  const [shareSpace, setShareSpace] = useState(false);
  const [vmessage, setVmessage] = useState(props.vmessage);
  const [warnMessage, setwarnMessage] = useState("");

  const handleRangeChange = (value) => {
    setRowWidth(value);
    let width = Number(parseFloat(value).toFixed(2));

    if (width >= 2) {
      setSelectedStreet("Local Street");
      dispatch({ type: "STREET-TYPE", payload: "Local" });
      dispatch({ type: "SHOW-ONE-WAY-CARRIAGEWAY", payload: false });
      dispatch({ type: "SHOW-CYCLETRACK-BOTH-SIDES", payload: false });
      // dispatch({ type: "BIKELANE-LEFT", payload: 0 });
      // dispatch({ type: "BIKELANE-RIGHT", payload: 2.5 });
      // dispatch({ type: "BIKELANE-MEDIAN-LEFT", payload: 0 });
      // dispatch({ type: "BIKELANE-MEDIAN-RIGHT", payload: 0.5 });
    }

    if (width >= 13) {
      setSelectedStreet("Collector Street");
      dispatch({ type: "STREET-TYPE", payload: "Collector" });
      dispatch({ type: "SHOW-ONE-WAY-CARRIAGEWAY", payload: false });
      dispatch({ type: "SHOW-CYCLETRACK-BOTH-SIDES", payload: false });
      // dispatch({ type: "BIKELANE-LEFT", payload: 0 });
      // dispatch({ type: "BIKELANE-RIGHT", payload: 2.5 });
      // dispatch({ type: "BIKELANE-MEDIAN-LEFT", payload: 0 });
      // dispatch({ type: "BIKELANE-MEDIAN-RIGHT", payload: 0.5 });
    }

    if (width >= 21) {
      setSelectedStreet("Avenue Street");
      dispatch({ type: "STREET-TYPE", payload: "Avenue" });
      dispatch({ type: "SHOW-ONE-WAY-CARRIAGEWAY", payload: true });
      dispatch({ type: "SHOW-CYCLETRACK-BOTH-SIDES", payload: true });
    }

    if (width >= 31) {
      setSelectedStreet("Boulevard Street");
      dispatch({ type: "STREET-TYPE", payload: "Boulevard" });
      dispatch({ type: "SHOW-ONE-WAY-CARRIAGEWAY", payload: true });
      dispatch({ type: "SHOW-CYCLETRACK-BOTH-SIDES", payload: true });
    }

    dispatch({ type: "ROW-WIDTH", payload: width });
  };

  const [rowWidth, setRowWidth] = useState(state.data.street.width);
  // const [rowWidth, setRowWidth] = useState(10);
  const [selectedStreet, setSelectedStreet] = useState("Boulevard Street");

  useEffect(() => {
    if (parseFloat((rowWidth - props.totalWidth).toFixed(2)) > 0) {
      console.log("It should not here");
      setVmessage("");
    } else {
      setVmessage(props.vmessage);
    }
  }, [rowWidth, props.vmessage]);

  useEffect(() => {
    if (showState.showRightPath === null && showState.showLeftPath === null) {
      setShareSpace(true);
      return;
    }
    setShareSpace(false);
  }, [showState.showRightPath, showState.showLeftPath]);

  const horizontalLabels = {
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
    60: "60",
  };

  return (
    //Original Color: #F8F3F7
    <Card
      body
      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
    >
      <div className="streetRow">
        <div className="streetTitle wrapper">
          <div id="divRow" style={{ marginBottom: 20 }}>
            <Label id="divRowLabel">{t("right_of_way")}</Label>
          </div>

          {/* <UncontrolledPopover
            trigger="legacy"
            placement="top"
            target="divRowLabel"
          >
            <PopoverHeader></PopoverHeader>
            <PopoverBody>
              The total width legally available for the street
            </PopoverBody>
          </UncontrolledPopover> */}
          <PopOver target="divRowLabel" body={t("right_of_way_desc")} />
        </div>

        <div className="streetcontentForm mb-4">
          <Row>
            <Col xs="12" lg="8">
              <div className="rangelBlock">
                <Row
                  style={{ textAlign: "center" }}
                  className="d-flex flex-row mb-4"
                >
                  <Col xs="12" lg="auto" className="pl-3 mb-3 m-0 p-0">
                    <div class="radiobtn flex-grow" id="divLocalStreet">
                      <Input
                        type="radio"
                        id="rbLocalStreet"
                        name="row_radios"
                        value="Local Street"
                        onClick={() => {
                          hideToolTip("#rbLocalStreet");
                          setRowWidth(10);
                          dispatch({ type: "STREET-TYPE", payload: "Local" });
                          setSelectedStreet("Local Street");
                          dispatch({
                            type: "SHOW-ONE-WAY-CARRIAGEWAY",
                            payload: false,
                          });
                          dispatch({
                            type: "SHOW-CYCLETRACK-BOTH-SIDES",
                            payload: false,
                          });
                          dispatch({ type: "ROW-WIDTH", payload: 10 });
                          // dispatch({ type: "BIKELANE-LEFT", payload: 0 });
                          // dispatch({ type: "BIKELANE-RIGHT", payload: 2.5 });
                          // dispatch({ type: "BIKELANE-MEDIAN-LEFT", payload: 0 });
                          // dispatch({ type: "BIKELANE-MEDIAN-RIGHT", payload: 0.5 });
                        }}
                        checked={selectedStreet === "Local Street"}
                      />
                      <Label for="rbLocalStreet" class="commonLabel">
                        {t("local_street")}
                      </Label>
                    </div>

                    <UncontrolledPopover
                      trigger="legacy"
                      placement="top"
                      target="divLocalStreet"
                    >
                      <PopoverHeader></PopoverHeader>
                      <PopoverBody>
                        {t("local_street_desc")}
                        <FaInfoCircle className="text-info" />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                  <Col xs="12" lg="auto" className="pl-3 mb-3 m-0 p-0">
                    <div class="radiobtn" id="divCollectorStreet">
                      <Input
                        type="radio"
                        id="rbCollectorStreet"
                        name="row_radios"
                        value="Collector Street"
                        onClick={() => {
                          hideToolTip("#rbCollectorStreet");
                          setRowWidth(20);
                          setSelectedStreet("Collector Street");
                          dispatch({
                            type: "STREET-TYPE",
                            payload: "Collector",
                          });
                          dispatch({
                            type: "SHOW-ONE-WAY-CARRIAGEWAY",
                            payload: false,
                          });
                          dispatch({
                            type: "SHOW-CYCLETRACK-BOTH-SIDES",
                            payload: false,
                          });
                          dispatch({ type: "ROW-WIDTH", payload: 20 });
                          // dispatch({ type: "BIKELANE-LEFT", payload: 0 });
                          // dispatch({ type: "BIKELANE-RIGHT", payload: 2.5 });
                          // dispatch({ type: "BIKELANE-MEDIAN-LEFT", payload: 0 });
                          // dispatch({ type: "BIKELANE-MEDIAN-RIGHT", payload: 0.5 });
                        }}
                        checked={selectedStreet === "Collector Street"}
                      />
                      <Label for="rbCollectorStreet">
                        {t("collector_street")}
                      </Label>
                    </div>

                    <UncontrolledPopover
                      trigger="legacy"
                      placement="top"
                      target="divCollectorStreet"
                    >
                      <PopoverHeader></PopoverHeader>
                      <PopoverBody>
                        {t("collector_street_desc")}
                        <FaInfoCircle className="text-info" />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                  <Col xs="12" lg="auto" className="pl-3 mb-3 m-0 p-0">
                    <div class="radiobtn" id="divAvenueStreet">
                      <Input
                        type="radio"
                        id="rbAvenueStreet"
                        name="row_radios"
                        value="Avenue Street"
                        onClick={() => {
                          hideToolTip("#rbAvenueStreet");
                          setRowWidth(30);
                          setSelectedStreet("Avenue Street");
                          dispatch({ type: "STREET-TYPE", payload: "Avenue" });
                          dispatch({
                            type: "SHOW-ONE-WAY-CARRIAGEWAY",
                            payload: true,
                          });
                          dispatch({
                            type: "SHOW-CYCLETRACK-BOTH-SIDES",
                            payload: true,
                          });
                          dispatch({ type: "ROW-WIDTH", payload: 30 });
                        }}
                        checked={selectedStreet === "Avenue Street"}
                      />
                      <Label for="rbAvenueStreet">{t("avenue_street")}</Label>
                    </div>

                    <UncontrolledPopover
                      trigger="legacy"
                      placement="top"
                      target="divAvenueStreet"
                    >
                      <PopoverHeader></PopoverHeader>
                      <PopoverBody>
                        {t("avenue_street_desc")}
                        <FaInfoCircle className="text-info" />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                  <Col xs="12" lg="auto" className="pl-3 mb-3 m-0 p-0">
                    <div class="radiobtn" id="divBoulevardStreet">
                      <Input
                        type="radio"
                        id="rbBoulevardStreet"
                        name="row_radios"
                        value="Boulevard Street"
                        onClick={() => {
                          hideToolTip("#rbBoulevardStreet");
                          setRowWidth(40);
                          setSelectedStreet("Boulevard Street");
                          dispatch({
                            type: "STREET-TYPE",
                            payload: "Boulevard",
                          });
                          dispatch({
                            type: "SHOW-ONE-WAY-CARRIAGEWAY",
                            payload: true,
                          });
                          dispatch({
                            type: "SHOW-CYCLETRACK-BOTH-SIDES",
                            payload: true,
                          });
                          dispatch({ type: "ROW-WIDTH", payload: 40 });
                        }}
                        checked={selectedStreet === "Boulevard Street"}
                      />
                      <Label for="rbBoulevardStreet">
                        {t("boulevard_street")}
                      </Label>
                    </div>

                    <UncontrolledPopover
                      trigger="legacy"
                      placement="top"
                      target="divBoulevardStreet"
                    >
                      <PopoverHeader></PopoverHeader>
                      <PopoverBody>
                        {t("boulevard_street_desc")}
                        <FaInfoCircle className="text-info" />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    {/* <Input
                          type="range"
                          name="range"
                          id="exampleRange"
                          min={1}
                          max="60"
                          step="0.1"
                          value={rowWidth}
                          defaultValue={state.data.street.width}
                          onChange={handleRangeChange}
                        /> */}
                    <div className="custom_progress">
                      <div className="slider">
                        <Slider
                          min={1}
                          max={60}
                          step={0.1}
                          value={rowWidth}
                          tooltip={false}
                          onChange={handleRangeChange}
                          labels={horizontalLabels}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs="12" lg="4">
              <div className="wrapper vcenter-item mb-2 w-100 ml-auto">
                <span
                  className="regularText text-left lineHeight21"
                  style={{ paddingRight: "32px" }}
                >
                  {t("available_street_width")}
                </span>
                <div className="valueBlock">
                  <div className="valuebtn_outer mb-3 " style={{ height: 60 }}>
                    <InputGroup className="valueBtn">
                      <InputGroupText
                        onClick={() => {
                          if (rowWidth > 1) {
                            setRowWidth(
                              parseFloat((rowWidth - 0.05).toFixed(2))
                            );
                            dispatch({
                              type: "ROW-WIDTH",
                              payload: parseFloat((rowWidth - 0.05).toFixed(2)),
                            });
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="30"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M5 11h14v2H5z" fill="rgba(58,65,98,1)" />
                        </svg>
                      </InputGroupText>
                      <Input
                        aria-label="Amount (to the nearest dollar)"
                        value={handleNumber(rowWidth)}
                        placeholder={handleNumber(rowWidth)}
                        onChange={(e) => {
                          const val = parseFloat(e.target.value);
                          setRowWidth(handleNumber(val > 120 ? 120 : val));
                          dispatch({
                            type: "ROW-WIDTH",
                            payload: handleNumber(val > 120 ? 120 : val),
                          });
                          if (val > 120) {
                            console.log(val > 120);
                            setwarnMessage(
                              `${t("increasing_right_of_way")} 120${t("meter")}`
                            );
                          } else {
                            setwarnMessage(t(""));
                          }
                        }}
                        max={70}
                      />
                      <InputGroupText
                        onClick={() => {
                          // if (rowWidth < 60) {
                            setRowWidth(handleNumber(Number(rowWidth) + 1.00));
                            dispatch({
                              type: "ROW-WIDTH",
                              payload: handleNumber(Number(rowWidth) + 1.00),
                            });
                          // }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                            fill="rgba(58,65,98,1)"
                          />
                        </svg>
                      </InputGroupText>
                    </InputGroup>
                    <span className="meter-label w-auto h-auto">
                      {" "}
                      {t("meter")}
                    </span>
                  </div>
                </div>
              </div>
              {vmessage !== "" ||
                (warnMessage !== "" && (
                  <div
                    className="bg-danger-light mt-2 mb-3 vcenter-item  ml-auto p-3 text-dark"
                    style={{ borderRadius: 15 }}
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 34 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.285 1.98614L0.383385 25.7951C0.132273 26.2251 4.64755e-05 26.7128 1.22494e-08 27.2094C-4.6451e-05 27.7059 0.132088 28.1936 0.38312 28.6236C0.634152 29.0536 0.995234 29.4107 1.43007 29.659C1.8649 29.9072 2.35815 30.0379 2.86025 30.0378H30.6612C31.1633 30.0379 31.6566 29.9072 32.0914 29.659C32.5262 29.4107 32.8873 29.0536 33.1384 28.6236C33.3894 28.1936 33.5215 27.7059 33.5215 27.2094C33.5214 26.7128 33.3892 26.2251 33.1381 25.7951L19.238 1.98614C18.987 1.55627 18.626 1.1993 18.1913 0.951111C17.7566 0.702925 17.2634 0.572266 16.7615 0.572266C16.2595 0.572266 15.7664 0.702925 15.3317 0.951111C14.897 1.1993 14.536 1.55627 14.285 1.98614Z"
                        fill="#EE404C"
                      />
                      <path
                        d="M16.9425 9.3291H16.5799C15.6871 9.3291 14.9634 10.0448 14.9634 10.9277V18.5686C14.9634 19.4515 15.6871 20.1672 16.5799 20.1672H16.9425C17.8353 20.1672 18.5591 19.4515 18.5591 18.5686V10.9277C18.5591 10.0448 17.8353 9.3291 16.9425 9.3291Z"
                        fill="#FFF7ED"
                      />
                      <path
                        d="M16.7612 26.2272C17.7542 26.2272 18.5591 25.4312 18.5591 24.4493C18.5591 23.4674 17.7542 22.6714 16.7612 22.6714C15.7683 22.6714 14.9634 23.4674 14.9634 24.4493C14.9634 25.4312 15.7683 26.2272 16.7612 26.2272Z"
                        fill="#FFF7ED"
                      />
                    </svg>
                    <p className="regularText lineHeight21 mb-0 ml-3">
                      {vmessage !== "" ? vmessage : warnMessage}
                    </p>
                  </div>
                ))}

              <div className="wrapper vcenter-item mb-2 w-100 ml-auto">
                <span
                  className="regularText text-left lineHeight21"
                  style={{ paddingRight: "32px", whiteSpace: "normal" }}
                >
                  {t("remaining_street_width")}
                </span>
                <div className="valueBlock">
                  <div className="valuebtn_outer mb-3" style={{ height: 60 }}>
                    <InputGroup
                      className="valueBtn"
                      style={{ borderColor: "transparent" }}
                    >
                      <InputGroupText style={{ cursor: "auto" }} />
                      <Input
                        aria-label="Amount (to the nearest dollar)"
                        value={`${
                          shareSpace
                            ? 0
                            : parseFloat(
                                (rowWidth - props.totalWidth).toFixed(2)
                              )
                        }${t("meter")}`}
                        disabled
                        style={{
                          background: "transparent",
                          borderColor: "transparent",
                          color:
                            !shareSpace && rowWidth - props.totalWidth < 0
                              ? "red"
                              : "#000000",
                        }}
                      />
                      <InputGroupText style={{ cursor: "auto" }} />
                    </InputGroup>
                    <span
                      className="meter-label w-auto h-auto"
                      style={{ visibility: "hidden" }}
                    >
                      {" "}
                      {t("meter")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default RowCard;

