import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div class="footer">
      <Container fluid>
        <Row style={{ alignItems: "center" }}>
          <Col xs={12} sm={12} md={12} lg={8}>
            <Nav className="ml-auto mr-auto" style={{}}>
              <NavItem>
                <NavLink href="https://ethiopiastreets.net/wp-content/uploads/2023/11/Ethiopia-Urban-Street-Design-Manual-230802.pdf">
                  {t("design_manual")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://ethiopiastreets.net/privacy-policy-2/">
                  {" "}
                  {t("privacy")}{" "}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://ethiopiastreets.net/terms-conditions/">
                  {" "}
                  {t("terms")}{" "}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            <Nav className="ml-auto mr-auto socialIcon" style={{}}>
              <NavItem>
                <NavLink href="https://web.facebook.com/Ministry-of-Urban-Development-and-ConstructionEthiopia-1502536229847000/?hc_ref=ARR31LDnpwaeh0UPo6Pi6G9UOUjGILdWrpn0wJUdVTL9ATAGu7y74HhdQAVNXW8DgmQ&fref=nf&__xts__%5B0%5D=68.ARAjRsnDbhMVKTSQ4Xh6DohJQZHxSeYGRX0mnr15Xc_vwkzYiGx9dBhAJ9XWd5nCdri0auTAYtc5u8QnGoFzgzLbU-2oHEE6CZTuDQtj6zLAmyrCHpwZ7q_PXPu6CrlqBF6p-SW0QdXtGaSWvoT-9Muq6KeX3mI_vHqMIBzBp3x9z7p_B73nDTGPvjHdGWeMIVhX1m1q87Pfj92-S7XTBv9KPKRA1hAKCr-_L2r4yiJ1b1kOIvNSiOEfDUT8Z6k7EeSByHpU2Gs6rKNGkcASAPIKJBzg9jhcbmVGhA6By4QtPz1SB1QUY7w&_rdc=1&_rdr">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
                      fill="rgba(43,160,108,1)"
                    />
                  </svg>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://twitter.com/MUDHCo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
                      fill="rgba(43,160,108,1)"
                    />
                  </svg>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
