import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState("GB");

  const changeLan = (code) => {
    setSelected(code);
    if (code === "ET") {
      document.body.classList.remove("body_lang_change");
      i18n.changeLanguage("am");
    } else {
      document.body.classList.add("body_lang_change");
      i18n.changeLanguage("en");
    }
  };

  return (
    <>
      <div className="switch_box box_4">
        <div className="input_wrapper">
          <input
            type="checkbox"
            className="switch_4"
            checked={selected === "GB"}
            onChange={(e) => {
              changeLan(e.target.checked ? "GB" : "ET");
            }}
          />
          {selected === "ET" ? (
            <svg
              className={selected === "GB" ? "is_checked" : "is_unchecked"}
              width="1em"
              height="2em"
              viewBox="0 0 512 336"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none">
                <path
                  d="M503.172 335.725H8.828A8.829 8.829 0 010 326.897V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
                  fill="#41479B"
                ></path>
                <path
                  d="M512 9.104a8.829 8.829 0 00-8.828-8.828h-39.495l-163.54 107.147V.276h-88.276v107.147L48.322.276H8.828A8.829 8.829 0 000 9.104v22.831l140.309 91.927H0v88.276h140.309L0 304.066v22.831a8.829 8.829 0 008.828 8.828h39.495l163.54-107.147v107.147h88.276V228.578l163.54 107.147h39.495a8.829 8.829 0 008.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512 31.935V9.104z"
                  fill="#F5F5F5"
                ></path>
                <g fill="#FF4B55">
                  <path d="M512 141.518H282.483V.276h-52.966v141.242H0v52.965h229.517v141.242h52.966V194.483H512z"></path>
                  <path d="M178.948 212.138L.25 328.135c.625 4.263 4.14 7.59 8.577 7.59h12.159l190.39-123.587-32.428.001v-.001zm167.44 0H313.96l190.113 123.404c4.431-.472 7.928-4.09 7.928-8.646v-7.258l-165.613-107.5zM0 18.849l161.779 105.014h32.428L5.143 1.137C2.123 2.54 0 5.555 0 9.104v9.745zm332.566 105.014L511.693 7.586c-.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138 123.863h32.428z"></path>
                </g>
              </g>
            </svg>
          ) : (
            <svg
              className="is_unchecked"
              width="1em"
              height="2em"
              viewBox="0 0 512 336"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none">
                <path
                  d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
                  fill="#73AF00"
                ></path>
                <path
                  d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
                  fill="#FF4B55"
                ></path>
                <path fill="#FFE15A" d="M0 112.088h512V223.9H0z"></path>
                <path
                  d="M361.945 168.098c0 58.426-47.435 105.945-105.945 105.945-58.51 0-105.945-47.519-105.945-105.945 0-58.594 47.435-106.029 105.945-106.029 58.51 0 105.945 47.435 105.945 106.029z"
                  fill="#4173CD"
                ></path>
                <g fill="#F2DD30">
                  <path d="M175.42 141.463h61.556l13.929-42.867 3.699 11.498-20.228 62.316-6.026-4.396 6.301-19.362h-49.506l-9.725-7.189zm30.756 94.785l19.025-58.51-36.462-26.551h12.026l52.995 38.473-6.024 4.396-16.509-12.007-15.325 47.181-9.726 7.018zm99.646 0L256 200.061l-36.442 26.464 3.721-11.329 52.952-38.558 2.262 7.102-16.468 11.923 40.057 29.172 3.74 11.413zm30.756-94.785l-49.781 36.274 13.931 42.869-9.724-7.104-20.209-62.316h7.399l6.298 19.448 40.079-29.172h12.006v.001h.001zM256 82.953l19.025 58.51h45.088l-9.745 7.189h-65.487l2.305-7.189h20.418l-15.325-47.096L256 82.953z"></path>
                  <path d="M257.775 252.822h-3.53V206.91h3.53v45.912zm78.276-56.991l-43.651-14.12 1.1-3.297 43.672 14.12-1.121 3.297zm-160.123 0l-1.1-3.297 43.692-14.12 1.08 3.297-43.672 14.12zm104.361-58.173l-2.877-2.028 26.995-37.12 2.812 2.03-26.93 37.118zm-48.513 0l-27.038-37.118 2.855-2.03 26.973 37.12-2.79 2.028z"></path>
                </g>
              </g>
            </svg>
          )}
        </div>
      </div>
    </>
  );
}

export default LanguageSwitcher;

