import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: () => {
        // check the domain
        const host = window.location.host;
        return ((host === 'constantinfo.net' || host === 'konstantlab.com') ? '/stmix':'') + '/locales/{{lng}}/{{ns}}.json';
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18next;
