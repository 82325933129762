import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  Card,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import AppContext from "../AppContext";
import { handleNumber } from "./Utils";
import { useTranslation } from "react-i18next";

const BrtCard = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const { showDispatch } = useContext(AppContext);
  const [vmessage, setVMessage] = useState("");

  const stationWidth = (action, value) => {
    const existNUm = Number(state.data.street.segments[16].width);
    let number = Number.parseFloat(
      action === "add" ? existNUm + 1.00 : existNUm - 1.00
    ).toFixed(2);
    if (action === "input") {
      number = parseFloat(value);
    }
    number = number < 0 ? 0 : number;
    if (number < 4) {
      setVMessage(t("increasing_brt_station_width"));
    } else {
      setVMessage("");
    }

    dispatch({
      type: "BRT-STATION",
      payload: number || existNUm,
    });
  };

  useEffect(() => {
    dispatch({ type: "BRT-STATION", payload: 4 });
    showDispatch({
      type: "SHOW-BRT-PASSINGLANES",
      payload: true,
    });
    dispatch({ type: "BRTLANE-ONE-LEFT", payload: 3.5 });
    dispatch({ type: "BRTLANE-TWO-LEFT", payload: 3.5 });
    dispatch({ type: "BRTLANE-ONE-RIGHT", payload: 3.5 });
    dispatch({ type: "BRTLANE-TWO-RIGHT", payload: 3.5 });
  }, []);

  return (
    <Card
      body
      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
    >
      <div className="streetRow">
        <div className="streetTitle wrapper mb-3">
          <Label>{t("brt_station")}</Label>
        </div>
        <div className="streetcontentForm mb-4">
          <Row>
            {/* <Col xs="2" className="wrapper vcenter-item">
          <div>
            <Label>BRT Station</Label>
          </div>
        </Col> */}
            <Col xs="12" lg="auto" className="mb-3">
              <div class="radiobtn">
                <Input
                  onClick={() => {
                    dispatch({ type: "BRT-STATION", payload: 0 });
                    showDispatch({
                      type: "SHOW-BRT-PASSINGLANES",
                      payload: null,
                    });
                    dispatch({ type: "BRTLANE-TWO-LEFT", payload: 0 });
                    dispatch({ type: "BRTLANE-TWO-RIGHT", payload: 0 });
                  }}
                  type="radio"
                  id="rbBRTPassingLanes"
                  name="rbBrtCard"
                />
                <Label for="rbBRTPassingLanes">{t("off_station")}</Label>
              </div>
            </Col>
            <Col xs="12" lg="auto" className="mb-3">
              <div class="radiobtn">
                <Input
                  type="radio"
                  id="rbBRTStation"
                  name="rbBrtCard"
                  onClick={() => {
                    dispatch({ type: "BRT-STATION", payload: 4 });
                    showDispatch({
                      type: "SHOW-BRT-PASSINGLANES",
                      payload: true,
                    });
                    dispatch({ type: "BRTLANE-ONE-LEFT", payload: 3.5 });
                    dispatch({ type: "BRTLANE-TWO-LEFT", payload: 3.5 });
                    dispatch({ type: "BRTLANE-ONE-RIGHT", payload: 3.5 });
                    dispatch({ type: "BRTLANE-TWO-RIGHT", payload: 3.5 });
                  }}
                  defaultChecked={true}
                />
                <Label for="rbBRTStation">{t("at_station")}</Label>
              </div>
            </Col>
            <Col />
            {/* <Col xs="auto" className="wrapper vcenter-item">
          <div>
            <Label>Station Width</Label>
          </div>
        </Col> */}
            <Col xs="12" lg="4">
              <div className="wrapper vcenter-item mb-2 w-75 ml-auto">
                <span className="regularText text-left lineHeight21">
                  {t("station_width")}
                </span>
                <div className="valueBlock">
                  <div className="valuebtn_outer" style={{ height: 60 }}>
                    <InputGroup className="valueBtn">
                      <InputGroupText onClick={() => stationWidth("minus")}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="30"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M5 11h14v2H5z" fill="rgba(58,65,98,1)" />
                        </svg>
                      </InputGroupText>
                      <Input
                        aria-label="Amount (to the nearest dollar)"
                        value={handleNumber(
                          state.data.street.segments[16].width
                        )}
                        placeholder={handleNumber(
                          state.data.street.segments[16].width
                        )}
                        max="4"
                        onChange={(e) =>
                          stationWidth("input", handleNumber(e.target.value))
                        }
                      />
                      <InputGroupText onClick={() => stationWidth("add")}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                            fill="rgba(58,65,98,1)"
                          />
                        </svg>
                      </InputGroupText>
                    </InputGroup>
                    <span className="meter-label w-auto h-auto">
                      {" "}
                      {t("meter")}
                    </span>
                  </div>
                </div>
              </div>

              {vmessage && (
                <div
                  className="bg-danger-light mt-3 vcenter-item  ml-auto p-3 text-dark"
                  style={{ borderRadius: 15 }}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 34 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.285 1.98614L0.383385 25.7951C0.132273 26.2251 4.64755e-05 26.7128 1.22494e-08 27.2094C-4.6451e-05 27.7059 0.132088 28.1936 0.38312 28.6236C0.634152 29.0536 0.995234 29.4107 1.43007 29.659C1.8649 29.9072 2.35815 30.0379 2.86025 30.0378H30.6612C31.1633 30.0379 31.6566 29.9072 32.0914 29.659C32.5262 29.4107 32.8873 29.0536 33.1384 28.6236C33.3894 28.1936 33.5215 27.7059 33.5215 27.2094C33.5214 26.7128 33.3892 26.2251 33.1381 25.7951L19.238 1.98614C18.987 1.55627 18.626 1.1993 18.1913 0.951111C17.7566 0.702925 17.2634 0.572266 16.7615 0.572266C16.2595 0.572266 15.7664 0.702925 15.3317 0.951111C14.897 1.1993 14.536 1.55627 14.285 1.98614Z"
                      fill="#EE404C"
                    />
                    <path
                      d="M16.9425 9.3291H16.5799C15.6871 9.3291 14.9634 10.0448 14.9634 10.9277V18.5686C14.9634 19.4515 15.6871 20.1672 16.5799 20.1672H16.9425C17.8353 20.1672 18.5591 19.4515 18.5591 18.5686V10.9277C18.5591 10.0448 17.8353 9.3291 16.9425 9.3291Z"
                      fill="#FFF7ED"
                    />
                    <path
                      d="M16.7612 26.2272C17.7542 26.2272 18.5591 25.4312 18.5591 24.4493C18.5591 23.4674 17.7542 22.6714 16.7612 22.6714C15.7683 22.6714 14.9634 23.4674 14.9634 24.4493C14.9634 25.4312 15.7683 26.2272 16.7612 26.2272Z"
                      fill="#FFF7ED"
                    />
                  </svg>
                  <p className="regularText lineHeight21 mb-0 ml-3">
                    {vmessage}
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default BrtCard;

