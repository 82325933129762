import React, { useContext } from "react";
import { FaInfoCircle } from "react-icons/fa";
import {
  Row,
  Col,
  Label,
  Input,
  Card,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import AppContext from "../AppContext";
import { hideToolTip } from "./Utils";
import { useTranslation } from "react-i18next";

const StreetTopologyCard = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);

  return (
    <Card
      body
      style={{ backgroundColor: "transparent", borderColor: "transparent" }}
    >
      <div className="streetRow">
        <div className="streetTitle wrapper mb-4">
          <Label>{t("street_typology")}</Label>
        </div>
        <div className="streetcontentForm mb-4">
          <Row>
            <Col xs-12>
              <Row>
                <Col xs="12" lg="auto" className="pl-3 mb-3">
                  <div class="radiobtn" id="divMixedUseStreet">
                    <Input
                      type="radio"
                      id="rbMixedUseStreet"
                      name="street_topology_radios"
                      defaultChecked={true}
                      onClick={() => {
                        hideToolTip("#rbMixedUseStreet");
                      }}
                      onChange={(e) => {
                        dispatch({
                          type: "STREET-TOPOLOGY",
                          payload: "MixedUse",
                        });
                        // dispatch({
                        //   type: "DRIVELANE-THREE-LEFT",
                        //   payload: 3.0,
                        // });
                        // dispatch({ type: "DRIVELANE-FOUR-LEFT", payload: 3.0 });
                        // dispatch({ type: "DRIVELANE-ONE-RIGHT", payload: 3.0 });
                        // dispatch({ type: "DRIVELANE-TWO-RIGHT", payload: 3.0 });
                      }}
                    />
                    <Label for="rbMixedUseStreet">{t("mixed_street")}</Label>
                  </div>

                  <UncontrolledPopover
                    trigger="legacy"
                    placement="top"
                    target="divMixedUseStreet"
                  >
                    <PopoverHeader></PopoverHeader>
                    <PopoverBody>
                    {t("mixed_street_desc")}
                      <FaInfoCircle className="text-info" />
                    </PopoverBody>
                  </UncontrolledPopover>
                </Col>
                <Col xs="12" lg="auto" className="pl-3 mb-3">
                  <div class="radiobtn" id="divHistoricalStreet">
                    <Input
                      type="radio"
                      id="rbHistoricalStreet"
                      name="street_topology_radios"
                      onClick={() => {
                        hideToolTip("#rbHistoricalStreet");
                      }}
                      onChange={(e) => {
                        dispatch({
                          type: "STREET-TOPOLOGY",
                          payload: "Historical",
                        });
                        // dispatch({
                        //   type: "DRIVELANE-THREE-LEFT",
                        //   payload: 3.0,
                        // });
                        // dispatch({ type: "DRIVELANE-FOUR-LEFT", payload: 3.0 });
                        // dispatch({ type: "DRIVELANE-ONE-RIGHT", payload: 3.0 });
                        // dispatch({ type: "DRIVELANE-TWO-RIGHT", payload: 3.0 });
                      }}
                    />
                    <Label for="rbHistoricalStreet">{t("historical_street")}</Label>
                  </div>

                  <UncontrolledPopover
                    trigger="legacy"
                    placement="top"
                    target="divHistoricalStreet"
                  >
                    <PopoverHeader></PopoverHeader>
                    <PopoverBody>
                    {t("historical_street_desc")}
                      <FaInfoCircle className="text-info" />
                    </PopoverBody>
                  </UncontrolledPopover>
                </Col>
                <Col xs="12" lg="auto" className="pl-3 mb-3">
                  <div class="radiobtn" id="divIndustrialStreet">
                    <Input
                      type="radio"
                      id="rbIndustrialStreet"
                      name="street_topology_radios"
                      onClick={() => {
                        hideToolTip("#rbIndustrialStreet");
                      }}
                      onChange={(e) => {
                        dispatch({
                          type: "STREET-TOPOLOGY",
                          payload: "Industrial",
                        });
                        // dispatch({
                        //   type: "DRIVELANE-THREE-LEFT",
                        //   payload: 3.5,
                        // });
                        // dispatch({ type: "DRIVELANE-FOUR-LEFT", payload: 3.5 });
                        // dispatch({ type: "DRIVELANE-ONE-RIGHT", payload: 3.5 });
                        // dispatch({ type: "DRIVELANE-TWO-RIGHT", payload: 3.5 });
                      }}
                    />
                    <Label for="rbIndustrialStreet">{t("industrial_street")}</Label>
                  </div>

                  <UncontrolledPopover
                    trigger="legacy"
                    placement="top"
                    target="divIndustrialStreet"
                  >
                    <PopoverHeader></PopoverHeader>
                    <PopoverBody>
                    {t("industrial_street_desc")}
                      <FaInfoCircle className="text-info" />
                    </PopoverBody>
                  </UncontrolledPopover>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default StreetTopologyCard;
